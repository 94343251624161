.signature-pad-container {
    width: 100%;
    /* max-width: 400px; */
    /* You can adjust the maximum width */
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #ccc;
    /* Add your desired border style */
    border-radius: 5px;

}

.signature-pad {
    border: 1px solid #ccc;
    box-shadow: inset 0 0 10px rgba(171, 171, 171, 0.2);
    /* You can customize the border for the canvas */
}
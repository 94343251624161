.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
PW Realty Platform Customized CSS

Author: Jack Chu
JC WEB HUB (https://jcwebhub.com)
*/

.border-pw{
  border-color: #f15b22 !important;
}

.btn-pw{
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background-color: #f15b22 !important;
  border-color: #f15b22 !important;
  text-transform: none;
}

.btn-pw:hover{
  background-color: #ca4c1d !important;
  border-color: #ca4c1d !important;
}

.bg-pw{
  background-color: #f15b22 !important;
}

a.bg-pw:hover{
  background-color: #ca4c1d !important;
}

.form-input:focus {
  border-color: #f15b22 !important;
}

.text-pw{
  color: #f15b22;
}

.link-pw{
  color: #f15b22 !important;
}

.link-pw:hover{
  color: #ca4c1d !important;
}

/* .text-pw{
  color: #f15b22 !important;
} */

/* Target the scrollbar track (the area the thumb moves in) */
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

/* Target the scrollbar thumb (the part you drag) */
::-webkit-scrollbar-thumb {
  background-color: #b6b6b6; /* Set the background color of the thumb */
  border-radius: 6px; /* Add rounded corners to the thumb */
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6; 
  /* #f15d228f; Slightly darker color on hover */
  width: 15px; /* Increase the thumb width on hover */
}

/* Target the scrollbar track when the user hovers over it */
::-webkit-scrollbar-track:hover {
  background-color: #e1e1e1; /* Change background color on hover */
}

.dropzone-container {
  border: 1px dashed #d2d2d2;
  /* background-color: #f0f0f0;   Change background color */
  /* Add other custom styles as needed */
}

.dropzone-container:hover{
  border-color: #f15b22;
}

.dropzone{
  padding: 50px;
  cursor: pointer;
  /* background-color: #f4f4f4; */
  color: rgb(121, 121, 121);;
}


.uploaded-files-container > ul > li {
  border: 1px solid #ccc;   /* Add a border */
  border-radius: 4px;
  position: relative;
  /* Add other custom styles as needed */
}


.invalid-input {
  border-color: #f15b22 !important;
}


.menu li > *:not(ul):not(.menu-title):not(details):active, .menu li > *:not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
  background-color: #ca4c1d;
  color: #fff;
}

input:focus{
  outline: none !important;
}

select:focus{
  outline: none !important;
}

textarea:focus {
  outline: none !important
}


 /* Hide the default checkbox */
 input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox container */
.pw-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #d7d7d7;
  border-radius: 10px;
}

/* Style the custom checkbox when checked */
input[type="checkbox"]:checked + .pw-checkbox {
  background-color: #f15b22;
  border-color: #f15b22;
}

/* Style the checkmark icon */
.checkmark {
  position: absolute;
  top: 2px;
  left: 5px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked + .pw-checkbox .checkmark {
  display: block;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh !important;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #bebebe29;
}


.main-container{ 
  flex:1; 
  margin-top: 80px;
  margin-bottom:50px;
}


#root, #root > main, #root > main > div {
  height: 100% !important;
  min-height: 100vh;
  display:flex; flex-direction:column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
